<template>
  <div class="upload">
    <template v-if="uploadSuccess">
      <div class="upload-success">
        <h2>Upload Successful!</h2>
        <p>Click the following link to access the uploaded video:</p>
        <a :href="uploadLink">{{ uploadLink }}</a>
        <button @click="copyLink">Copy Link</button>
        <button @click="uploadSuccess = !uploadSuccess">Continue uploading</button>
      </div>
    </template>
    <template v-else>
      <form @submit.prevent="onSubmit" class="form-container">
        <label class="form-label">
          Title:<br>
          <input v-model="form.title" type="text" class="form-input">
        </label><br>

        <label class="form-label">
          Cover URL:<br>
          <input v-model="form.cover_url" type="text" class="form-input">
        </label><br>

        <label class="form-label">
          Video URL:<br>
          <input v-model="form.video_url" type="text" class="form-input">
        </label><br>

        <label class="form-label">
          Description:<br>
          <textarea v-model="form.description" class="form-textarea"></textarea>
        </label><br>

        <label class="form-label">
          Tags:<br>
          <input v-model="form.tags" type="text" class="form-input">
        </label>

        <label class="form-label">
          Categories:<br>
          <div style="margin-top: 8px" class="form-category">
            <label v-for="(category, index) in categories" :key="index" :for="`category-${index}`">
              <input type="radio" :id="`category-${index}`" v-model="form.category_id" :value="index + 1">
              {{ category }}
            </label>
          </div>
        </label><br>
        <div style="    display: flex;
    justify-content: space-between;">
          <button class="button" type="submit">Submit</button>
          <button class="button" type="button" @click="$router.push('/');">Back</button>
        </div>
      </form>

      <h2>Form Data:</h2>
      <pre><code>{{ form }}</code></pre>
    </template>
  </div>
</template>

<script>
import activeInstance from "@/router/api"
import storageService from "@/pages/home/service/storageService";
import jwt_decode from "jwt-decode";

export default {
  name: "Upload",
  data() {
    return {
      form: {
        title: '',
        cover_url: '',
        video_url: '',
        description: '',
        tags: '',
        category_id: 3
      },
      categories: [
        "番剧",
        "国创",
        "电影",
        "电视剧",
        "综艺",
        "动画",
        "舞蹈",
        "娱乐",
        "美食",
        "时尚",
        "旅游",
        "生活",
        "资讯",
        "亲子",
        "知识",
        "影视",
        "游戏",
        "汽车",
        "财经",
        "萌宠",
        "运动",
        "音乐",
        "短片",
        "科技",
        "健康",
        "纪实类",
        "其他"
      ],
      uploadSuccess: false,
      uploadLink: ""
    };
  },
  created() {
    this.loadAuth()
  },
  methods: {
    onSubmit() {
      const data = {
        title: this.form.title,
        description: this.form.description,
        url: this.form.video_url,
        cover_url: this.form.cover_url,
        tags: this.form.tags,
        category_id: this.form.category_id
      };

      // 在这里提交表单数据
      // 例如使用 axios 发送一个 POST 请求
      activeInstance.post('/api/v1/videos', data)
          .then(response => {
            const data = response.data
            if (data.code !== 0) {
              this.$message.error(data.message);
              return
            }
            // 在这里处理响应数据
            this.uploadSuccess = true;
            this.uploadLink = this.uploadLink = window.location.origin + "/video/" + data.data.vid;
          })
    },
    copyLink() {
      if (navigator.clipboard) {
        // 使用 Clipboard API 复制文本到剪贴板中
        navigator.clipboard.writeText(this.uploadLink)
            .then(() => {
              this.$message.info("文本已经复制到剪贴板");
            })
            .catch((error) => {
              this.$message.info("无法复制文本");
              console.error('无法复制文本', error);
            });
      } else {
        // 浏览器不支持 Clipboard API
        this.$message.warning("该浏览器不支持 Clipboard API");
      }
    },
    loadAuth() {
      // 从本地存储中获取JWT
      const token = storageService.get(storageService.USER_TOKEN);
      if (token === null) {
        window.location.href = '/login';
      }
      // 解码token并获取"exp"属性
      const decodedToken = jwt_decode(token);
      const tokenExp = decodedToken.exp;

      // 获取当前时间戳
      const currentTime = Date.now() / 1000; // 将时间戳转换为以秒为单位
      // 判断token是否已过期
      if (tokenExp < currentTime) {
        // 如果token已过期，需要重新进行身份验证
        localStorage.removeItem('aikan_user_info');
        localStorage.removeItem('aikan_user_token');
        // 重定向到登录页面
        window.location.href = '/login';
      }
    }
  }
};
</script>

<style scoped>
.upload {
  padding: 10px;
}

.form-container {
  max-width: 400px;
  margin: 20px auto;
}

.form-label {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.form-input, .form-textarea {
  width: 100%;
  margin-top: 8px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

pre {
  font-size: 12px;
  padding: 0;
  margin: 0;
  background: #f0f0f0;
  line-height: 20px; /* 行距 */
  background: url(https://www.shejidaren.com/examples/pre-style/pre-bg.gif) repeat-y left top;
  width: 600px;
  overflow: auto; /* 超出宽度出现滑动条 */
  overflow-Y:hidden; /* 作用是隐藏IE的右侧滑动条 */
}

pre code {
  margin: 0 0 0 40px;
  padding: 20px 0;
  display: block;
}

.upload-success {
  text-align: center;
  margin-top: 20px;
}

.upload-success h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.upload-success p {
  font-size: 16px;
  margin-bottom: 10px;
}

.upload-success a {
  display: inline-block;
  margin-bottom: 10px;
  color: #007bff;
  text-decoration: none;
}

.upload-success button {
  display: block;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-category {
  --background: #ffffff;
  --text: #414856;
  --radio: #7C96B2;
  --radio-checked: #4F29F0;
  --radio-size: 20px;
  --width: 150px;
  --height: 140px;
  --border-radius: 10px;
  background: var(--background);
  border-radius: var(--border-radius);
  color: var(--text);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: 0 10px 30px rgba(65, 72, 86, 0.05);
}

.form-category label {
  display: flex;
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
  margin: 10px;
}

.form-category input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  height: var(--radio-size);
  width: var(--radio-size);
  outline: none;
  cursor: pointer;
  border: 2px solid var(--radio);
  background: transparent;
  border-radius: 50%;
  display: grid;
  justify-self: end;
  justify-items: center;
  align-items: center;
  overflow: hidden;
  transition: border .5s ease;
  margin-right: 10px;
}

.form-category input[type="radio"]::before, .form-category input[type="radio"]::after {
  content: "";
  display: flex;
  justify-self: center;
  border-radius: 50%;
}

.form-category input[type="radio"]::before {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--background);
  z-index: 1;
  opacity: var(--opacity, 1);
}

.form-category input[type="radio"]::after {
  position: relative;
  width: calc(100% / 2);
  height: calc(100% / 2);
  background: var(--radio-checked);
  top: var(--y, 100%);
  transition: top 0.5s cubic-bezier(0.48, 1.97, 0.5, 0.63);
}

.form-category input[type="radio"]:checked {
  --radio: var(--radio-checked);
}

.form-category input[type="radio"]:checked::after {
  --y: 0%;
  animation: stretch-animate .3s ease-out .17s;
}

.form-category input[type="radio"]:checked::before {
  --opacity: 0;
}

.form-category input[type="radio"]:checked ~ input[type="radio"]::after {
  --y: -100%;
}

.form-category input[type="radio"]:not(:checked)::before {
  --opacity: 1;
  transition: opacity 0s linear .5s;
}

@keyframes stretch-animate {
  0% {
    transform: scale(1, 1);
  }

  28% {
    transform: scale(1.15, 0.85);
  }

  50% {
    transform: scale(0.9, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.socials > a {
  display: block;
  width: 30px;
  opacity: .2;
  transform: scale(var(--scale, 0.8));
  transition: transform 0.3s cubic-bezier(0.38, -0.12, 0.24, 1.91);
}

.socials > a:hover {
  --scale: 1;
}

/*button style*/
.button {
  position: relative;
  padding: 10px 22px;
  border-radius: 6px;
  border: none;
  color: #fff;
  cursor: pointer;
  background-color: #7d2ae8;
  transition: all 0.2s ease;
}

.button:active {
  transform: scale(0.96);
}

.button:before,
.button:after {
  position: absolute;
  content: "";
  width: 150%;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  z-index: -1000;
  background-repeat: no-repeat;
}

.button:hover:before {
  top: -70%;
  background-image: radial-gradient(circle, #7d2ae8 20%, transparent 20%),
  radial-gradient(circle, transparent 20%, #7d2ae8 20%, transparent 30%),
  radial-gradient(circle, #7d2ae8 20%, transparent 20%),
  radial-gradient(circle, #7d2ae8 20%, transparent 20%),
  radial-gradient(circle, transparent 10%, #7d2ae8 15%, transparent 20%),
  radial-gradient(circle, #7d2ae8 20%, transparent 20%),
  radial-gradient(circle, #7d2ae8 20%, transparent 20%),
  radial-gradient(circle, #7d2ae8 20%, transparent 20%),
  radial-gradient(circle, #7d2ae8 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%,
  10% 10%, 18% 18%;
  background-position: 50% 120%;
  animation: greentopBubbles 0.6s ease;
}

@keyframes greentopBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
    40% 90%, 55% 90%, 70% 90%;
  }

  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
    50% 50%, 65% 20%, 90% 30%;
  }

  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
    50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

.button:hover::after {
  bottom: -70%;
  background-image: radial-gradient(circle, #7d2ae8 20%, transparent 20%),
  radial-gradient(circle, #7d2ae8 20%, transparent 20%),
  radial-gradient(circle, transparent 10%, #7d2ae8 15%, transparent 20%),
  radial-gradient(circle, #7d2ae8 20%, transparent 20%),
  radial-gradient(circle, #7d2ae8 20%, transparent 20%),
  radial-gradient(circle, #7d2ae8 20%, transparent 20%),
  radial-gradient(circle, #7d2ae8 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 20% 20%, 18% 18%;
  background-position: 50% 0%;
  animation: greenbottomBubbles 0.6s ease;
}

@keyframes greenbottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
    70% -10%, 70% 0%;
  }

  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
    105% 0%;
  }

  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
    110% 10%;
    background-size: 0 0, 0 0, 0 0, 0 0, 0 0, 0 0;
  }
}
</style>
